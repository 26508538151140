<template>
	<div>
		<!-- 商家进账报表 -->
		<div class="queryBox mb10">
			<div class="mb10">
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">{{$t('main.reportMenu.theDayBefore')}}</el-button>
				<el-date-picker size="mini" v-model="date" type="datetimerange" :range-separator="$t('main.reportMenu.to')" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">{{$t('main.reportMenu.theNextDay')}}</el-button>
				
				
				<span class="inlineBlock mr10">
					<span>通道：</span>
					<el-select v-model="payway_type_id"  :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr10" clearable>
						<el-option v-for="item in paywayTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</span>
				
				<span class="inlineBlock mr10">
					<span>{{$t('main.reportMenu.agent')}}：</span>
					
					<el-cascader v-model="agent" class="mr10" :show-all-levels="false" :placeholder="$t('main.reportMenu.pleaseChoose')" size="mini" :options="agenttableData" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'username',label:'name' }" clearable @change="agentChang"></el-cascader>
				</span>
				<span class="inlineBlock mr10">
					<span>{{$t('main.reportMenu.business')}}：</span>
					<el-select v-model="trader" :placeholder="$t('main.reportMenu.pleaseChoose')" size="mini" clearable>
						<el-option v-for="item in tradertableData" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
					</el-select>
				</span>
				<span class="inlineBlock mr10">
					<span>{{$t('main.reportMenu.collectionMethod')}}：</span>
					<el-select v-model="pay_type" :placeholder="$t('main.reportMenu.pleaseChoose')" size="mini" clearable>
						<el-option v-for="item in paywayArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</span>
				<el-button size="mini" :icon="disLoading?'el-icon-loading':'el-icon-brush'" @click="resetCondition" :disabled="disLoading">重置</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">{{$t('main.reportMenu.query')}}</el-button>
				<el-button size="mini" type="primary" icon="el-icon-document"  @click="getExcel" :disabled="!tableData.length">导出报表</el-button>
			</div>
			
			<div class="mb10">
					<el-tag type="danger" effect="dark" class="custom-tag mr10">
						<div class="flexY">
							<span>今日</span>
							<span>统计</span>
						</div>
					</el-tag>
					
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>订单总数</span>
							<span>{{ todayDataTotal.add_count }}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功单数</span>
							<span>{{ todayDataTotal.success_count }}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功金额</span>
							<span>{{ todayDataTotal.success_money | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>商户手续费</span>
							<span>{{ Number(todayDataTotal.fee_gold_tra)  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>商户收入</span>
							<span>{{ todayDataTotal.trader_gold  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>通道利润</span>
							<span>{{ Number(todayDataTotal.fee_gold_payway_type)| tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>代理利润</span>
							<span>{{ Number(todayDataTotal.fee_gold_ag) | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>平台利润</span>
							<span>{{ todayDataTotal.fee_gold_adm  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="warning" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功率</span>
							<span>{{ todayDataTotal.success_ratio }}%</span>
						</div>
				    </el-tag>
			</div>
			<div>
					<el-tag type="danger" effect="dark" class="custom-tag mr10">
						<div class="flexY">
							<span>昨日</span>
							<span>统计</span>
						</div>
					</el-tag>
					
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>订单总数</span>
							<span>{{ yestodayDataTotal.add_count }}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功单数</span>
							<span>{{ yestodayDataTotal.success_count }}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功金额</span>
							<span>{{ yestodayDataTotal.success_money | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>商户手续费</span>
							<span>{{ Number(yestodayDataTotal.fee_gold_tra) | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>商户收入</span>
							<span>{{ yestodayDataTotal.trader_gold  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>通道利润</span>
							<span>{{ Number(yestodayDataTotal.fee_gold_payway_type) | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>代理利润</span>
							<span>{{ Number(yestodayDataTotal.fee_gold_ag) | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>平台利润</span>
							<span>{{ yestodayDataTotal.fee_gold_adm  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="warning" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功率</span>
							<span>{{ yestodayDataTotal.success_ratio }}%</span>
						</div>
				    </el-tag>
			</div>
			
		</div>
		<template>
			<el-tabs v-model="activeName" type="card" @tab-click="handleTab">
			  <el-tab-pane label="商户" name="trader">
				  <el-table v-if="group_type=='trader'" :max-height="height" :data="tableData" style="width: 100%" v-loading="loading" size="mini">
				  	<el-table-column :label="$t('main.reportMenu.serialNumber')">
				  		<template slot-scope="scope">
				  			<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
				  			<span v-else>{{scope.$index + 1}}</span>
				  		</template>
				  	</el-table-column>
				  	<el-table-column :label="$t('main.reportMenu.business')" width="160">
				  		<template slot-scope="scope">
							<el-link type="primary" @click="setBusinesss(scope.row, 'trader_detail')">{{scope.row.business_name}} <i v-if="scope.row.business_name" class="el-icon-s-data"></i></el-link>
				  		</template>
				  	</el-table-column>
				  	<el-table-column label="平均耗时" width="100" prop="avg_action_time">
				  		<template slot-scope="scope">
							
							<span v-if="scope.$index == tableData.length-2 || scope.$index == tableData.length-1"></span>
				  			<div v-else class="csuccess"><i class="el-icon-time"></i> {{ Math.floor(scope.row.avg_action_time) || 0 }}秒</div>
				  		</template>
				  	</el-table-column>
				  	<el-table-column :label="$t('main.reportMenu.submit')">
				  		<el-table-column prop="add_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
				  		<el-table-column :label="$t('main.reportMenu.amount')">
				  			<template slot-scope="scope">
				  			<span>{{scope.row.add_money | tofixed}}</span>
				  			</template>
				  		</el-table-column>
				  	</el-table-column>
				  	<el-table-column :label="$t('main.reportMenu.success')" >
				  		<el-table-column prop="success_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
				  		<el-table-column :label="$t('main.reportMenu.amount')">
				  			<template slot-scope="scope">
				  				<span>{{scope.row.success_money | tofixed}}</span>
				  			</template>
				  		</el-table-column>
				  	</el-table-column>
				  	
				  	<el-table-column :label="$t('main.reportMenu.successRate')">
				  		<el-table-column :label="$t('main.reportMenu.numberOfOrder')">
				  			<template slot-scope="scope">
				  				<span>{{scope.row.success_count_ratio | tofixed}}%</span>
				  			</template>
				  		</el-table-column>
				  		<el-table-column :label="$t('main.reportMenu.amount')">
				  			<template slot-scope="scope">
				  				<span>{{scope.row.success_money_ratio | tofixed}}%</span>
				  			</template>
				  		</el-table-column>
				  	</el-table-column>
				  	<el-table-column :label="$t('main.reportMenu.passageway')">
				  		<template slot-scope="scope">
				  			<span>{{scope.row.fee_gold_payway_type | tofixed}}</span>
				  		</template>
				  	</el-table-column>
				  	<el-table-column :label="$t('main.reportMenu.agent')">
				  		<template slot-scope="scope">
				  			<span>{{scope.row.fee_gold_ag | tofixed}}</span>
				  		</template>
				  	</el-table-column>
				  	<el-table-column :label="$t('main.reportMenu.platform')">
				  		<template slot-scope="scope">
				  			<span>{{scope.row.platform_gold | tofixed}}</span>
				  		</template>
				  	</el-table-column>
				  	<el-table-column :label="$t('main.reportMenu.business')">
				  		<template slot-scope="scope">
				  			<span>{{scope.row.trader_gold | tofixed}}</span>
				  		</template>
				  	</el-table-column>
				  </el-table>
				  
					<el-table v-if="group_type == 'trader_detail'" :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini" :span-method="objectSpanMethod">
						
						<el-table-column :label="$t('main.reportMenu.business')" width="160">
							<template slot-scope="scope">
								<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
								<span v-else="">{{scope.row.business_name}}</span>
								
							</template>
						</el-table-column>
						<el-table-column label="代收方式" width="80">
							<template slot-scope="scope">
								<span v-if="scope.row.pay_type">【{{scope.row.pay_type}}】{{scope.row.pay_type_name}}</span>
							</template>
						</el-table-column>
						<el-table-column label="平均耗时" width="100" prop="avg_action_time">
							<template slot-scope="scope">
								
								<span v-if="scope.$index == tableData.length-2 || scope.$index == tableData.length-1"></span>
								<div v-else class="csuccess"><i class="el-icon-time"></i> {{ Math.floor(scope.row.avg_action_time) || 0 }}秒</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="payway_name" :label="$t('main.reportMenu.passageway')" width="100" >
							<template slot-scope="scope">
								<div v-if="scope.row.agent_username && !scope.row.agent_username.includes('总计')" >{{scope.row.payway_name}}</div>
							</template>
						</el-table-column> -->
						<el-table-column :label="$t('main.reportMenu.submit')" >
							<el-table-column prop="add_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')" >
								<template slot-scope="scope">
									
									<span>{{scope.row.add_money | tofixed}}</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.success')">
							<el-table-column prop="success_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									<span>{{scope.row.success_money | tofixed}}</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.successRate')" >
							<el-table-column :label="$t('main.reportMenu.numberOfOrder')" >
								<template slot-scope="scope">
									<span>{{scope.row.success_count_ratio | tofixed}}%</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									<span>{{scope.row.success_money_ratio | tofixed}}%</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.passageway')">
							<template slot-scope="scope">
								<span>{{scope.row.fee_gold_payway_type | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.agent')">
							<template slot-scope="scope">
								<span>{{scope.row.fee_gold_ag | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.platform')">
							<template slot-scope="scope">
								<span>{{scope.row.platform_gold | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.business')">
							<template slot-scope="scope">
								<span>{{scope.row.trader_gold | tofixed}}</span>
							</template>
						</el-table-column>
					</el-table>
			  </el-tab-pane>
			  <el-tab-pane label="通道" name="payway">
  
				  <el-table v-if="group_type=='payway_type'" :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini">
					<el-table-column :label="$t('main.reportMenu.serialNumber')" >
						<template slot-scope="scope">
							<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
							<span v-else>{{scope.$index + 1}}</span>
						</template>
					</el-table-column>
					<el-table-column label="通道" width="160" >
						<template slot-scope="scope">
							
							<el-link type="primary" @click="setBusinesss(scope.row, 'payway_type_detail')">{{scope.row.payway_name}} <i v-if="scope.row.payway_name" class="el-icon-s-data"></i></el-link>
						</template>
					</el-table-column>
					<el-table-column label="平均耗时" width="100" prop="avg_action_time">
						<template slot-scope="scope">
							
							<span v-if="scope.$index == tableData.length-2 || scope.$index == tableData.length-1"></span>
							<div v-else class="csuccess"><i class="el-icon-time"></i> {{ Math.floor(scope.row.avg_action_time) || 0 }}秒</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.submit')">
						<el-table-column prop="add_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
						<el-table-column :label="$t('main.reportMenu.amount')">
							<template slot-scope="scope">
							<span>{{scope.row.add_money | tofixed}}</span>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.success')">
						<el-table-column prop="success_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
						<el-table-column :label="$t('main.reportMenu.amount')">
							<template slot-scope="scope">
								<span>{{scope.row.success_money | tofixed}}</span>
							</template>
						</el-table-column>
					</el-table-column>
					
					<el-table-column :label="$t('main.reportMenu.successRate')">
						<el-table-column :label="$t('main.reportMenu.numberOfOrder')">
							<template slot-scope="scope">
								<span>{{scope.row.success_count_ratio | tofixed}}%</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.amount')">
							<template slot-scope="scope">
								<span>{{scope.row.success_money_ratio | tofixed}}%</span>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.passageway')">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_payway_type | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.agent')">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_ag | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.platform')">
						<template slot-scope="scope">
							<span>{{scope.row.platform_gold | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.business')">
						<template slot-scope="scope">
							<span>{{scope.row.trader_gold | tofixed}}</span>
						</template>
					</el-table-column>
				  </el-table>
				  
					<el-table v-if="group_type == 'payway_type_detail'" :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini" :span-method="objectSpanMethod">
						
						<el-table-column label="通道" width="160">
							<template slot-scope="scope">
								<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
								<span v-else="">{{scope.row.payway_name}}</span>
							</template>
						</el-table-column>
						<el-table-column label="代收方式" width="80">
							<template slot-scope="scope">
								<span v-if="scope.row.pay_type">【{{scope.row.pay_type}}】{{scope.row.pay_type_name}}</span>
							</template>
						</el-table-column>
						<el-table-column label="平均耗时" width="100" prop="avg_action_time">
							<template slot-scope="scope">
								
								<span v-if="scope.$index == tableData.length-2 || scope.$index == tableData.length-1"></span>
								<div v-else class="csuccess"><i class="el-icon-time"></i> {{ Math.floor(scope.row.avg_action_time) || 0 }}秒</div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.submit')">
							<el-table-column prop="add_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									
									<span>{{scope.row.add_money | tofixed}}</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.success')">
							<el-table-column prop="success_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									<span>{{scope.row.success_money | tofixed}}</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.successRate')">
							<el-table-column :label="$t('main.reportMenu.numberOfOrder')">
								<template slot-scope="scope">
									<span>{{scope.row.success_count_ratio | tofixed}}%</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									<span>{{scope.row.success_money_ratio | tofixed}}%</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.passageway')">
							<template slot-scope="scope">
								<span>{{scope.row.fee_gold_payway_type | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.agent')">
							<template slot-scope="scope">
								<span>{{scope.row.fee_gold_ag | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.platform')">
							<template slot-scope="scope">
								<span>{{scope.row.platform_gold | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.business')">
							<template slot-scope="scope">
								<span>{{scope.row.trader_gold | tofixed}}</span>
							</template>
						</el-table-column>
					</el-table>
			  </el-tab-pane>
			  <el-tab-pane label="代理" name="ag">
  
				  <el-table v-if="group_type=='agent'" :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini">
					<el-table-column :label="$t('main.reportMenu.serialNumber')">
						<template slot-scope="scope">
							<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
							<span v-else>{{scope.$index + 1}}</span>
						</template>
					</el-table-column>
					<el-table-column label="代理" width="160">
						<template slot-scope="scope">
							<el-link type="primary" @click="setBusinesss(scope.row, 'agent_detail')">{{scope.row.agent_name}} <i v-if="scope.row.agent_name" class="el-icon-s-data"></i></el-link>
						</template>
					</el-table-column>
					<el-table-column label="平均耗时" width="100" prop="avg_action_time">
						<template slot-scope="scope">
							
							<span v-if="scope.$index == tableData.length-2 || scope.$index == tableData.length-1"></span>
							<div v-else class="csuccess"><i class="el-icon-time"></i> {{ Math.floor(scope.row.avg_action_time) || 0 }}秒</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.submit')">
						<el-table-column prop="add_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
						<el-table-column :label="$t('main.reportMenu.amount')">
							<template slot-scope="scope">
							<span>{{scope.row.add_money | tofixed}}</span>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.success')">
						<el-table-column prop="success_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
						<el-table-column :label="$t('main.reportMenu.amount')">
							<template slot-scope="scope">
								<span>{{scope.row.success_money | tofixed}}</span>
							</template>
						</el-table-column>
					</el-table-column>
					
					<el-table-column :label="$t('main.reportMenu.successRate')">
						<el-table-column :label="$t('main.reportMenu.numberOfOrder')">
							<template slot-scope="scope">
								<span>{{scope.row.success_count_ratio | tofixed}}%</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.amount')">
							<template slot-scope="scope">
								<span>{{scope.row.success_money_ratio | tofixed}}%</span>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.passageway')">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_payway_type | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.agent')">
						<template slot-scope="scope">
							<span>{{scope.row.fee_gold_ag | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.platform')">
						<template slot-scope="scope">
							<span>{{scope.row.platform_gold | tofixed}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.reportMenu.business')">
						<template slot-scope="scope">
							<span>{{scope.row.trader_gold | tofixed}}</span>
						</template>
					</el-table-column>
				  </el-table>
				  
					<el-table v-if="group_type == 'agent_detail'" :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini" :span-method="objectSpanMethod">
						
						<el-table-column label="代理" width="160">
							<template slot-scope="scope">
								<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
								<span v-else="">{{scope.row.agent_username}}</span>
							</template>
						</el-table-column>
						<el-table-column label="下属商户" width="80">
							<template slot-scope="scope">
								{{scope.row.business_name}}
							</template>
						</el-table-column>
						<el-table-column label="平均耗时" width="100" prop="avg_action_time">
							<template slot-scope="scope">
								
								<span v-if="scope.$index == tableData.length-2 || scope.$index == tableData.length-1"></span>
								<div v-else class="csuccess"><i class="el-icon-time"></i> {{ Math.floor(scope.row.avg_action_time) || 0 }}秒</div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.submit')">
							<el-table-column prop="add_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									
									<span>{{scope.row.add_money | tofixed}}</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.success')">
							<el-table-column prop="success_count" :label="$t('main.reportMenu.numberOfOrder')"></el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									<span>{{scope.row.success_money | tofixed}}</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.successRate')">
							<el-table-column :label="$t('main.reportMenu.numberOfOrder')">
								<template slot-scope="scope">
									<span>{{scope.row.success_count_ratio | tofixed}}%</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('main.reportMenu.amount')">
								<template slot-scope="scope">
									<span>{{scope.row.success_money_ratio | tofixed}}%</span>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.passageway')">
							<template slot-scope="scope">
								<span>{{scope.row.fee_gold_payway_type | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.agent')">
							<template slot-scope="scope">
								<span>{{scope.row.fee_gold_ag | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.platform')">
							<template slot-scope="scope">
								<span>{{scope.row.platform_gold | tofixed}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('main.reportMenu.business')">
							<template slot-scope="scope">
								<span>{{scope.row.trader_gold | tofixed}}</span>
							</template>
						</el-table-column>
					</el-table>
				  
			  </el-tab-pane>
			</el-tabs>
			
		</template>
		<div class="flexX pageBox mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>

	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				date: '',
				input: '',
				tableData: [],
				pageSizesArr:[1000, 2000,5000,10000],
				pageSize: 1000,
				total: 0,
				page: 1,
				paywayArr: [],
				pay_type: '',
				platformArr: [{
					value: '',
					label: '全部'
				}],
				platform: '',
				agent: '' ,//按代理查
				height:0,
				agenttableData:[],
				tradertableData:[],
				trader:'',
				group_type: 'trader',
				// 排序
				activeName:'trader',
				// 数据统计.
				todayDataTotal: [],
				yestodayDataTotal: [],
				paywayTypeList:[],
				payway_type_id: '',
				// 合并表格
				spanArr: [],
				pos: '',
				// size
				traderagentpageSize: 1000,
			}
		},
		created() {
			let date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.date =this.$route.query.date? this.$route.query.date:date
			this.pay_type =this.$route.query.recharge? Number(this.$route.query.recharge):''// 获取通道报表传过来的充值类型
			this.agent = this.$route.query.agent // 获取通道报表传过来的充值类型
			this.agentList()
			this.traderListtList()
			this.reportTrader()
			this.paywayAll()
			this.paywayTypeAll()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 40 - pageBox - 20 - queryBox - 30
			})
			this.orderTotal(1)
			setTimeout(() => {
			        this.orderTotal(2)
			      }, 700);
		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.getData()
				  }
				
			  }
			}
		},
		destroyed() {
			document.onkeydown = null
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					// return ((Math.floor((value * 1)*100)/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			//通道的筛选项
			paywayTypeAll(){
				this.$api.paywayTypeAll({}).then(res=>{
					this.paywayTypeList = res.data
				})
			},
			resetCondition(){
				this.resetDataCondition()
				this.getData()
			},
			resetDataCondition(){
				this.pay_type = ''
				this.trader = '';
				this.agent = '';
				this.payway_type_id = '';
				const now = new Date();
				const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
				const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
				  this.date = [
					this.$util.timestampToTime(startDate.getTime(), false),
					this.$util.timestampToTime(endDate.getTime(), false)
				  ];
				// this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			},
			getSpanArr(data) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this.spanArr.push(1);
						this.pos = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (this.group_type == 'trader_detail' && data[i].business_no === data[i - 1].business_no) {
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
						}else if(this.group_type == 'agent_detail' && data[i].agent_username === data[i - 1].agent_username){
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
							
						}else if(this.group_type == 'payway_type_detail' && data[i].payway_type_id === data[i - 1].payway_type_id){
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
							
						} else {
							this.spanArr.push(1);
							this.pos = i;
						}
					}
					// console.log(this.spanArr2)
				}
			},
			objectSpanMethod({row,column,rowIndex,columnIndex}) {
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					// console.log(`rowspan:${_row} colspan:${_col}`)
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
			setBusinesss(item, type){
				if(type == 'trader_detail'){
					this.trader = item.business_no
				}
				if(type == 'agent_detail'){
					this.agent = item.agent_username
				}
				if(type == 'payway_type_detail'){
					this.payway_type_id = item.payway_type_id
				}
				this.group_type = type
				this.reportTrader()
			},
			orderTotal(type) {
				let data = {
					type: type,
					agent_username: this.agent,
					business_no: this.business_no,
					payway_type_id:this.payway_type_id,
					business_name: this.business_name,
				}
				this.$api.reportOrderInTotal(data).then(res => {
					if (res.status === 1) {
						if(type == 1){
							this.todayDataTotal = res.data
						}
						if(type == 2){
							this.yestodayDataTotal = res.data
						}
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			handleTab(val){
				// this.resetDataCondition()
				if(this.activeName == 'trader'){
					this.group_type = 'trader';
				}
				if(this.activeName == 'payway'){
					this.group_type = 'payway_type';
				}
				if(this.activeName == 'ag'){
					this.group_type = 'agent';
				}
				this.reportTrader()
			},
			//导出Excel
			getExcel() {
				//manager/order/exportList
				let username = local.get('fourusername');
				let tid = local.get('fourtid')
				
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), false) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), false) : '';
				let href =
					`${this.$baseURL}/manager/report/exportTrader?username=${username}&tid=${tid}&startdate=${start_time}&enddate=${end_time}`
				// window.location.href = href
				window.open(href)
			},
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			paywayAll() {
				this.$api.paywayAll({hasRepay:2,page:1,size:1000}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//查看列表
			reportTrader() {
				let data = {
					// startdate: this.date,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					size: this.pageSize,
					page: this.page,
					group_type: this.group_type, //"group_type": "payway_type",// agent-代理  agent_detail-代理详情 trader-商户 trader_detail-商户详情 payway_type-通道 payway_type_detail-通道详情
					pay_type: this.pay_type,
					agent_username:this.agent,
					business_no:this.trader,
					payway_type_id:this.payway_type_id,
				}
				// this.loading = true
				this.tableData = []
				this.disLoading = true
				this.$api.orderReportGroup(data).then(res => {
					this.loading = false
					this.disLoading = false
					if (res.status == 1) {
						this.tableData = res.data.data
						this.total = res.data.total
						
						this.spanArr= []
						this.pos= ''
						this.getSpanArr(this.tableData)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			getData() {
				this.page = 1;
				this.reportTrader()
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.reportTrader()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.reportTrader()
			},
			gotopage(path, item) {
				this.$router.push({
					path: path,
					query: {
						business_no: item.business_no,
						date:[this.$util.timestampToTime(Date.parse(new Date(this.date[0]))),this.$util.timestampToTime(Date.parse(new Date(this.date[1])))]
					}
				})
			},
			//点击代理名称  查询当前代理的数据
			setAgent(item) {
				this.agent = item.agent_username;
				this.page = 1
				this.reportTrader()
				// console.log(this.agent)
			},
			//代理列表
			agentList() {
				this.$api.agentList({
					page: 1,
					size: this.traderagentpageSize
				}).then(res => {
					if (res.status === 1) {
						let agentData = res.data.filter(item=>{
							if(item.id){
								return item
							}
						})
						this.agenttableData = agentData
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			agentChang(value){
				this.agent = value
			},
			//商家列表
			traderListtList() {
				this.$api.traderList({
					page: 1,
					size: this.traderagentpageSize
				}).then(res => {
					if (res.status === 1) {
						this.tradertableData = res.data.data						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 40 - pageBox - 20 - queryBox - 30
				})
			}
		},
	}
</script>

<style>
	
	.custom-tag {
	  line-height: 0.9375rem;
	  white-space: normal;
	  height:auto;
	}
</style>
